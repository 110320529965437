import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const eventStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const eventSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const eventError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const eventCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.EVENT_START]: eventStart,
  [Types.EVENT_SUCCESS]: eventSuccess,
  [Types.EVENT_ERROR]: eventError,
  [Types.EVENT_CLEAN_UP]: eventCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
