import { Dispatch, FC, SetStateAction } from 'react';
import theme from 'config/theme';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { Body, UtilityText } from 'components/Typography';
import { formatNumberAsCurrency } from 'helpers/utils';
import { TicketWithQuantitySelected } from 'store/event/types';
import { Container, TicketContainer } from './styled';
import { CurrencyInput } from 'components/Slate/CustomSplit/CurrencyInput';
import { Ticket } from './Ticket';

interface Props {
  ticketSelections: TicketWithQuantitySelected[];
  setTicketSelections: Dispatch<SetStateAction<TicketWithQuantitySelected[]>>;
  customAmount: number;
  setCustomAmount: (val: number) => void;
}

export const Tickets: FC<Props> = ({
  ticketSelections,
  setTicketSelections,
  customAmount,
  setCustomAmount,
}) => {
  return (
    <Container>
      {ticketSelections
        ?.filter(ticket => ticket.status === 'ACTIVE')
        .map(ticket => (
          <Ticket key={ticket.id} setTicketSelections={setTicketSelections} {...ticket} />
        ))}
      <TicketContainer $justifyContent="space-between">
        <Column>
          <Body>Donate</Body>
          <UtilityText $color={theme.colors.gray}>
            Make an additional donation or donate without attending event.
          </UtilityText>
          <Spacing $size={8} />
          <Body>
            {customAmount > 0 ? formatNumberAsCurrency(customAmount) : 'Enter your amount'}
          </Body>
        </Column>
        <div>
          <CurrencyInput
            id="custom-amount"
            value={customAmount}
            onChange={val => setCustomAmount(val && val >= 0 ? val : 0)}
          />
        </div>
      </TicketContainer>
    </Container>
  );
};
