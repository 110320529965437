import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'config/theme';
import Loader from 'components/Loader';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { getPathWithRef, getUrlParams } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { ImpactCard } from './ImpactCard';
import { SummaryCard } from './SummaryCard';
import { SummaryModal } from './SummaryModal';
import { getRecipientsData } from 'selectors/recipients';
import { getRecipients } from 'store/recipients/actions';
import { DesktopLeftContainer } from 'components/DesktopLeftContainer';
import { IRecipient } from 'store/recipients/types';
import { Content, MainContainer, CardsContainer, ButtonContainer } from './styled';
import { ButtonBrand } from 'components/ButtonBrand';
import { Body } from 'components/Typography';
import { getAllPages } from 'selectors/pages';
import { getPages } from 'store/pages/actions';

export const SummaryScreen = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { urlPartner } = getUrlParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonWithShadow, setButtonWithShadow] = useState(true);
  const [recipientSelected, setRecipientSelected] = useState<IRecipient>();

  const { allPages, recipients } = useAppSelector(state => ({
    allPages: getAllPages(state),
    recipients: getRecipientsData(state),
  }));

  const hvpPartners = ['hvp'];
  const standardPartners = ['-details'];
  const partnersWithSummary = [...hvpPartners, ...standardPartners];

  const isLoadingPages = !allPages?.length;
  const isStandardPage = standardPartners.some(item => urlPartner?.includes(item));

  useEffect(() => {
    const element = ref.current;
    if (element) {
      const observer = new IntersectionObserver(([entry]) => {
        setButtonWithShadow(!entry.isIntersecting);
      });
      observer.observe(element);
    }
  }, [isLoadingPages]);

  useEffect(() => {
    if (!urlPartner || !partnersWithSummary.some(item => urlPartner.includes(item)))
      navigate(getPathWithRef('/'));
    else {
      if (!allPages) dispatch(getPages({ urlPartner }));
    }
  }, []);

  useEffect(() => {
    if (!recipients && urlPartner && allPages?.length && allPages[0].recipient_ids) {
      dispatch(getRecipients({ amount: 100, recipient_ids: allPages[0].recipient_ids || [] }));
    }
  }, [allPages]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = (rec_id: string) => {
    setRecipientSelected(recipients.find(recipient => recipient.recipient_id === rec_id));
    setIsModalOpen(true);
  };

  return isLoadingPages ? (
    <div className="loader-center">
      <Loader width={60} height={60} />
    </div>
  ) : (
    <>
      <Header urlPartner={urlPartner} />
      <MainContainer>
        <DesktopLeftContainer />
        <Content>
          <ImpactCard page={allPages[0]} />
          <CardsContainer>
            {recipients?.map((recipient, index) => (
              <SummaryCard
                key={index}
                recipient={recipient}
                openModal={handleOpenModal}
                isStandard={isStandardPage}
              />
            ))}
          </CardsContainer>
          {isStandardPage && (
            <>
              <ButtonContainer>
                <ButtonBrand
                  href={getPathWithRef(`/donate?p=${urlPartner}`)}
                  target="_blank"
                  $withShadow={buttonWithShadow}
                  $backgroundColor={theme.colors.oathBlue}
                  style={{ display: 'block', maxWidth: 600, margin: 'auto' }}
                >
                  <Body $color={theme.colors.white} $fontType="semi-bold">
                    DONATE NOW
                  </Body>
                </ButtonBrand>
              </ButtonContainer>
              <div ref={ref} />
            </>
          )}
        </Content>
      </MainContainer>
      <Footer />
      <SummaryModal
        isOpen={isModalOpen}
        closeModal={handleCloseModal}
        recipient={recipientSelected}
        isStandard={isStandardPage}
      />
    </>
  );
};
