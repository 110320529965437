import theme from 'config/theme';
import { RecurringAmountMobileContainer, RecurringCancelLink, RecurringCancelled } from './styled';
import { TSubscription } from 'store/subscriptions/types';
import { formatNumberAsCurrency, getFormattedDate, isKeyInObject } from 'helpers/utils';
import { BodySmall, UtilityText } from 'components/Typography';
import Spacing from 'components/Spacing';

export const monthlyTagNameMap = {
  'Monthly Flip the US House Slate': 'Flip the House',
  'Monthly Defeat Trump Slate': 'Defeat Trump',
  'Monthly Repro Slate': 'Reproductive Rights',
  'Monthly Protecting Democracy Slate': 'Protect Democracy',
  'Monthly Democratic Infrastructure Slate': 'Dem Infrastructure',
};

export const getMonthlyTagName = (tagName: string) => {
  if (!isKeyInObject(tagName, monthlyTagNameMap)) {
    return 'Monthly Slate';
  }
  return monthlyTagNameMap[tagName];
};

export const getColumnsMonthly = (
  getDeleteModalOpenHandler: (sub: TSubscription) => () => void,
  totalActiveSubscriptions: number
) => [
  {
    key: 'page_name',
    name: `Issue (${totalActiveSubscriptions})`,
    width: '35%',
    formatFn: (tagName: string, subscription: TSubscription) => (
      <>
        {getMonthlyTagName(tagName)}
        {subscription.state === 'active' ? (
          <RecurringCancelLink
            $color={theme.shadows.black(0.5)}
            onClick={getDeleteModalOpenHandler(subscription)}
          >
            Cancel
          </RecurringCancelLink>
        ) : (
          <RecurringCancelled $color={theme.shadows.black(0.5)}>Canceled</RecurringCancelled>
        )}
      </>
    ),
  },
  {
    key: 'next_transaction_date',
    name: 'Next Payment',
    formatFn: getFormattedDate,
    className: 'align-right',
  },
  {
    key: 'last_transaction_date',
    name: 'Last Payment',
    formatFn: getFormattedDate,
    className: 'align-right',
  },
  {
    key: 'amount',
    name: 'Donation Amount',
    formatFn: (amount: number) => formatNumberAsCurrency(amount, { trailingZeroDisplay: 'auto' }),
    width: '25%',
  },
];

export const getColumnsMonthlyMobile = (
  getDeleteModalOpenHandler: (sub: TSubscription) => () => void,
  totalActiveSubscriptions: number
) => [
  {
    key: 'tag_name',
    name: `Issue (${totalActiveSubscriptions})`,
    width: '50%',
    formatFn: (tagName: string, subscription: TSubscription) => (
      <>
        <div>
          <BodySmall $color="inherit">{getMonthlyTagName(tagName)}</BodySmall>
        </div>
        <Spacing $size={3} />
        <div>
          <UtilityText $color={subscription.state === 'active' ? theme.colors.black : 'inherit'}>
            Next Payment: {getFormattedDate(subscription.next_transaction_date)}
          </UtilityText>
        </div>
        <div>
          <UtilityText $color={subscription.state === 'active' ? theme.colors.black : 'inherit'}>
            Last Payment: {getFormattedDate(subscription.last_transaction_date)}
          </UtilityText>
        </div>
      </>
    ),
  },
  {
    key: 'amount',
    name: 'Donation Amount',
    formatFn: (amount: number, subscription: TSubscription) => (
      <RecurringAmountMobileContainer $justifyContent="space-between">
        <div>
          <BodySmall $color="inherit">
            {formatNumberAsCurrency(amount, { trailingZeroDisplay: 'auto' })}
          </BodySmall>
        </div>
        <div>
          {subscription.state === 'active' ? (
            <RecurringCancelLink
              $color={theme.shadows.black(0.5)}
              onClick={getDeleteModalOpenHandler(subscription)}
            >
              <UtilityText>Cancel</UtilityText>
            </RecurringCancelLink>
          ) : (
            <RecurringCancelled $color="inherit">
              <UtilityText $color="inherit">Canceled</UtilityText>
            </RecurringCancelled>
          )}
        </div>
      </RecurringAmountMobileContainer>
    ),
  },
];
