import { FC } from 'react';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import Spacing from 'components/Spacing';
import { Share } from 'components/Share';
import { codeFormatter } from 'helpers/utils';
import { IPage } from 'store/pages/types';
import {
  DescriptionSmall,
  DescriptionToggle,
  LeftBanner,
  LeftDescription,
  LeftTitlePartner,
  StyledImage,
} from './styled';

interface DonateProps {
  pageData: IPage;
  changeSrc: (ev: any) => void;
  showImage: boolean;
  isCondensedDescription: boolean;
  onDescriptionToggle: () => void;
}

export const DonatePageDetails: FC<DonateProps> = ({
  pageData,
  changeSrc,
  showImage,
  isCondensedDescription,
  onDescriptionToggle,
}) => {
  const textColor = pageData?.page_misc?.page_color_text || theme.colors.white;

  return (
    <>
      <LeftBanner $justifyContent="space-between">
        {showImage && (
          <StyledImage
            alt={`${pageData.value} logo`}
            src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/${ENV_CONFIG().CLOUD_STORAGE.TAGS}/tag_${
              pageData.id
            }.png`}
            onError={changeSrc}
          />
        )}
        <Share color={textColor} />
      </LeftBanner>
      <h1>
        <LeftTitlePartner dangerouslySetInnerHTML={{ __html: pageData.value }} $color={textColor} />
      </h1>
      <LeftDescription
        $hideForSmall={true}
        dangerouslySetInnerHTML={{
          __html: codeFormatter(pageData.desc),
        }}
        $color={textColor}
      />
      {pageData.desc && (
        <DescriptionSmall>
          <LeftDescription
            dangerouslySetInnerHTML={{ __html: codeFormatter(pageData.desc) }}
            $isCondensed={isCondensedDescription}
            $color={textColor}
          />
          <Spacing $size={isCondensedDescription ? 0 : 8} />
          <DescriptionToggle onClick={onDescriptionToggle} $color={textColor}>
            {isCondensedDescription ? 'View more' : 'View less'}
          </DescriptionToggle>
        </DescriptionSmall>
      )}
    </>
  );
};
