import { ButtonBrand } from 'components/ButtonBrand';
import { Row } from 'components/Row';
import { Body, Eyebrow, H4, UtilityText } from 'components/Typography';
import {
  ConfirmationBackgroundContainer,
  ConfirmationButtonRow,
  ConfirmationCardContainer,
  ConfirmationCardContent,
  ConfirmationContainer,
  ConfirmationContainerLeft,
  ConfirmationContainerRight,
  ConfirmationImage,
  ConfirmationPaymentDisplay,
  DonateAgainJumpButton,
  // IFrame,
  // IFrameContainer,
  ProcessingCardContent,
  ProcessingImage,
} from './styled';
import theme from 'config/theme';
import Spacing from 'components/Spacing';
import { formatNumber, formatNumberAsCurrency, getUrlParams, scrollToTop } from 'helpers/utils';
import { FC, useEffect } from 'react';
import { useAppSelector } from 'helpers/hooks';
import { getPayData } from 'selectors/pay';
import { PayResponseData } from 'store/pay/types';
import { PopupModal } from 'components/PopupModal';
import {
  checkoutLogFirebaseEvent,
  getDonationTotalFromPayResponse,
  getIsCheckoutMonthly,
  getTipFromPayResponse,
} from './Form/utils';
import { useShareUrl } from 'hooks/useShareUrl';
import { getUserState } from 'selectors/user';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { ProfileCard } from 'components/Cards/ProfileCard';
import { trackPixel } from 'helpers/pixel';
import Column from 'components/Column';
import { DonateAgain } from './DonateAgain';
import { ENV_CONFIG } from 'config/environment';

const DONATE_AGAIN_SECTION_ID = 'donate-again-section';

const getPaymentMethodDisplay = (payment: PayResponseData) => {
  const {
    payment_method: paymentMethod,
    cc_brand: ccBrand,
    cc_last_four: ccLastFour,
    ach_account_type: achType,
  } = payment;

  // TODO: verify last_4 for ach
  if (paymentMethod === 'credit_card') {
    return `${ccBrand} ${ccLastFour || '****'}`;
  }
  return `ACH ${achType} ****`;
};

const getIsDonationPending = (payment: PayResponseData) => {
  const { line_items: lineItems } = payment;
  return lineItems.some(
    item =>
      item.status === 'exception_during_authorization' ||
      item.status === 'authorization_awaiting_manual_review'
  );
};

interface Props {
  candidateCount: number;
  candidates: CandidateType[];
  donationAmount: number;
  handleNewDonationSelections: () => void;
  isEvent: boolean;
  pageImage?: string;
  postDonateMsg?: string;
}

export const Confirmation: FC<Props> = ({
  candidateCount,
  candidates,
  donationAmount,
  handleNewDonationSelections,
  isEvent,
  pageImage,
  postDonateMsg,
}) => {
  const { urlPartner } = getUrlParams();
  const { payment, userState } = useAppSelector(state => ({
    payment: getPayData(state),
    userState: getUserState(state),
  }));
  const { handleShare, isPopupOpen } = useShareUrl(userState?.data?.ref_code);
  const isDonateAgainVisible = urlPartner !== 'mx' && !isEvent;
  const textColor = isEvent ? theme.colors.white : theme.colors.inkBlue;
  const headerBackground = isEvent ? theme.colors.oathBlue : undefined;

  if (!payment) {
    window.location.href = `/donate${window.location.search}`;
    return null;
  }

  const dateObj = payment.created_at ? new Date(payment.created_at) : new Date();
  const date = dateObj.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  useEffect(() => {
    scrollToTop();
    trackPixel('purchase');
    trackPixel('universalPixel');
    checkoutLogFirebaseEvent({
      eventName: 'view_confirmation',
      userId: userState?.data?.id || '',
      candidates,
      donationAmount,
    });
  }, []);

  const scrollToDonateAgainSection = () => {
    const element = document.getElementById(DONATE_AGAIN_SECTION_ID);
    element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };

  // const pilotPartners = ['mx', 'dt', 'pd'];
  // const customPartners = ['bb'];
  // const iframeSrc =
  //   'https://app.sosha.ai/toolkit/986db138-b9d4-458f-b2fa-ba46aedb5c86?embedded=true&embedHeight=632&sliderEnabled=&showArrows=&iconsColor=%23000000&postBgColor=%23FFFFFF&postTextColor=%23000000&analyticsEnabled=';
  // const customPartnersIframeSrc =
  //   'https://app.sosha.ai/toolkit/0ecb8f48-dfad-488e-91af-41eb1a132f94?embedded=true&embedHeight=632&sliderEnabled=&showArrows=&iconsColor=%23000000&postBgColor=%23FFFFFF&postTextColor=%23000000&analyticsEnabled=';

  return (
    <>
      <ConfirmationBackgroundContainer
        $backgroundColor={isEvent ? theme.colors.inkBlue : undefined}
      >
        <ConfirmationContainer>
          <ConfirmationContainerLeft>
            <Eyebrow $color={textColor}>
              Donation
              {isEvent ? ' / Ticket Purchase' : ''}
              {getIsDonationPending(payment) ? ' pending' : ' complete'}
            </Eyebrow>
            <Spacing $size={5} />
            <H4 $color={textColor}>
              {getIsDonationPending(payment)
                ? 'Your donation is being processed'
                : 'Thank you for your donation!'}
            </H4>
            <Spacing $size={5} />
            <Body $color={textColor}>
              {getIsDonationPending(payment)
                ? 'We are currently processing your payment'
                : postDonateMsg ||
                  'Keep the momentum going and invite your friends and family to donate.'}
            </Body>
            {pageImage && <ConfirmationImage alt="Donate page logo" src={pageImage} />}
            {/* {urlPartner &&
            (customPartners.includes(urlPartner) || pilotPartners.includes(urlPartner)) &&
            !getIsDonationPending(payment) ? (
              <IFrameContainer>
                <IFrame
                  id="soshaIframe"
                  src={customPartners.includes(urlPartner) ? customPartnersIframeSrc : iframeSrc}
                  allow="camera; web-share"
                />
              </IFrameContainer>
            ) : ( */}
            <ConfirmationButtonRow>
              <ButtonBrand onClick={handleShare}>Share link</ButtonBrand>
              {isDonateAgainVisible && (
                <DonateAgainJumpButton
                  onClick={scrollToDonateAgainSection}
                  $secondary={true}
                  $hasBorder={true}
                >
                  Donate again
                </DonateAgainJumpButton>
              )}
            </ConfirmationButtonRow>
            {/* )} */}
          </ConfirmationContainerLeft>
          {getIsDonationPending(payment) ? (
            <ConfirmationCardContainer>
              <ProfileCard
                title="Pending Authorization Status"
                align="center"
                smallTitle={true}
                headerBackground={headerBackground}
              >
                <ProcessingCardContent>
                  <ProcessingImage
                    src={`${
                      ENV_CONFIG().CLOUD_STORAGE.URL
                    }/static_content/misc_icons/circle_arrows.png`}
                    alt="Circle formed by two arrows"
                  />
                  <Body>We are currently processing your payment.</Body>
                  <br />
                  <Body>Once received, we will send you an email confirming your donation.</Body>
                </ProcessingCardContent>
              </ProfileCard>
            </ConfirmationCardContainer>
          ) : (
            <ConfirmationContainerRight>
              <ConfirmationCardContainer>
                <ProfileCard title="Thank you!" align="center" headerBackground={headerBackground}>
                  <ConfirmationCardContent>
                    <Body>Contribution Summary</Body>
                    <Spacing $size={4} />
                    <Row $justifyContent="space-between">
                      <Column $gap="3px">
                        <H4>{formatNumberAsCurrency(getDonationTotalFromPayResponse(payment))}</H4>
                        <Spacing $size={2} />
                        <UtilityText>
                          {getIsCheckoutMonthly()
                            ? 'Recurring monthly contribution'
                            : 'One-time contribution'}
                        </UtilityText>
                        <UtilityText>
                          Including {formatNumberAsCurrency(getTipFromPayResponse(payment))} tip to{' '}
                          Oath
                        </UtilityText>
                      </Column>
                      <Column>
                        <H4>
                          {formatNumber(candidateCount)} recipient{candidateCount !== 1 && 's'}
                        </H4>
                      </Column>
                    </Row>
                    <Spacing $size={13} />
                    <Column $gap="3px">
                      <Body>{date}</Body>
                      <ConfirmationPaymentDisplay>
                        <Body>{getPaymentMethodDisplay(payment)}</Body>
                      </ConfirmationPaymentDisplay>
                      <Spacing $size={6} />
                      <Body>{payment.donor_full_name}</Body>
                      <Body>{payment.donor_email}</Body>
                    </Column>
                    {/* hiding until history is real-time <Spacing $size={22} />
                    <Row $around>
                      <Link $color={theme.colors.shaft} to={getPathWithRef('/profile')}>
                        <BodySmall $color={theme.colors.shaft}>View contribution history</BodySmall>
                      </Link>
                    </Row> */}
                  </ConfirmationCardContent>
                </ProfileCard>
              </ConfirmationCardContainer>
              <Spacing $size={14} />
              <Body>We’ve emailed you a receipt for your records.</Body>
            </ConfirmationContainerRight>
          )}
          <PopupModal autoClose={true} isSelected={isPopupOpen} title="Copied to Clipboard" />
        </ConfirmationContainer>
      </ConfirmationBackgroundContainer>
      {isDonateAgainVisible && (
        <DonateAgain
          id={DONATE_AGAIN_SECTION_ID}
          donationAmount={donationAmount}
          handleNewDonationSelections={handleNewDonationSelections}
        />
      )}
    </>
  );
};
