import { FC, useEffect, useRef, useState } from 'react';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import Icon, { IconNames } from 'components/Icon';
import { getUrlParams } from 'helpers/utils';
import { useAppSelector } from 'helpers/hooks';
import { getAllPages } from 'selectors/pages';
import { getEventData } from 'selectors/event';
import { DonatePageDetails } from './DonatePageDetails';
import { EventPageDetails } from './EventPageDetails';
import {
  CheckDescription,
  CheckTitle,
  IconWrapper,
  LeftDescriptionOath,
  LeftInfoContainer,
  LeftTitle,
  OathImage,
} from './styled';

interface Props {
  setRefWidthValue?: (val: number) => void;
}

export const DesktopLeftContainer: FC<Props> = ({ setRefWidthValue }) => {
  const ref = useRef(null);
  const { urlPartner, urlEvent } = getUrlParams();
  const [showImage, setShowImage] = useState(true);
  const [isCondensedDescription, setIsCondensedDescription] = useState(true);
  const { allPages, eventData } = useAppSelector(state => ({
    allPages: getAllPages(state),
    eventData: getEventData(state),
  }));

  let background = `linear-gradient(179.87deg, ${theme.colors.oathBlue} 9.51%, ${theme.colors.inkBlue} 106.69%)`;

  if (urlPartner) {
    background =
      urlEvent && eventData?.page_misc?.page_background
        ? eventData?.page_misc?.page_background
        : allPages?.[0]?.page_misc?.page_background || background;
  }

  useEffect(() => {
    const element: any = ref.current;
    if (element?.offsetWidth && setRefWidthValue) {
      setRefWidthValue(element.offsetWidth);
    }
  }, [ref]);

  const changeSrc = ev => {
    ev.target.src = '';
    setShowImage(false);
  };

  const onDescriptionToggle = () => {
    setIsCondensedDescription(isCondensed => !isCondensed);
  };

  return (
    <LeftInfoContainer ref={ref} $backgroundColor={background} $isPartnerPage={!!urlPartner}>
      {urlPartner ? (
        <>
          {eventData ? (
            <EventPageDetails
              eventData={eventData}
              changeSrc={changeSrc}
              showImage={showImage}
              isCondensedDescription={isCondensedDescription}
              onDescriptionToggle={onDescriptionToggle}
            />
          ) : (
            allPages?.length > 0 && (
              <DonatePageDetails
                pageData={allPages[0]}
                changeSrc={changeSrc}
                showImage={showImage}
                isCondensedDescription={isCondensedDescription}
                onDescriptionToggle={onDescriptionToggle}
              />
            )
          )}
        </>
      ) : (
        <>
          <LeftTitle>
            <OathImage
              alt="Oath logo"
              src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/Oath_logo_simple_White.png`}
            />
          </LeftTitle>
          <LeftDescriptionOath>Your one-stop-shop for political giving.</LeftDescriptionOath>
          <Spacing $size={32} />
          <Row>
            <IconWrapper>
              <Icon name={IconNames.Checkmark} color={theme.colors.inkBlue} size={14} />
            </IconWrapper>
            <Column>
              <CheckTitle>CAUSES YOU CARE FOR</CheckTitle>
              <CheckDescription>
                Oath is the smartest and easiest way to support the causes you care about.
              </CheckDescription>
            </Column>
          </Row>
          <Spacing $size={16} />
          <Row>
            <IconWrapper>
              <Icon name={IconNames.Checkmark} color={theme.colors.inkBlue} size={14} />
            </IconWrapper>
            <Column>
              <CheckTitle>MAXIMUM IMPACT</CheckTitle>
              <CheckDescription>Direct your money where it’s needed most.</CheckDescription>
            </Column>
          </Row>
          <Spacing $size={16} />
          <Row>
            <IconWrapper>
              <Icon name={IconNames.Checkmark} color={theme.colors.inkBlue} size={14} />
            </IconWrapper>
            <Column>
              <CheckTitle>ZERO SPAM</CheckTitle>
              <CheckDescription>Oath never sells or shares your data.</CheckDescription>
            </Column>
          </Row>
        </>
      )}
    </LeftInfoContainer>
  );
};
