import { useAppSelector } from 'helpers/hooks';
import { getUserData } from 'selectors/user';
import { RowContainer, LeftContent, RightContent, SmallText } from './styled';
import theme from 'config/theme';
import Spacing from 'components/Spacing';
import { getObjectDisplayName, formatNumberAsCurrency } from 'helpers/utils';
import { IEmailCadence, emailCadenceDictionary } from './constants';
import { H4 } from 'components/Typography';

export const AccountInfo = ({ topIssues }) => {
  const userData = useAppSelector(getUserData);

  return (
    <>
      <RowContainer>
        <LeftContent $width={'50%'}>
          <H4>Personal Details</H4>
          <SmallText color={theme.colors.inkBlue}>
            {userData.first_name} {userData.last_name}
          </SmallText>
          <SmallText color={theme.colors.inkBlue}>{userData.email}</SmallText>
          <Spacing $size={10} />
          {userData.address1 ? (
            <>
              <SmallText color={theme.colors.inkBlue}>
                {userData.address1}
                {userData.address2 && `, ${userData.address2}`}
              </SmallText>
              <SmallText color={theme.colors.inkBlue}>
                {userData.city}
                {userData.state && `, ${userData.state}`} {userData.zipcode}
              </SmallText>
            </>
          ) : (
            <SmallText color={theme.colors.inkBlue}>-</SmallText>
          )}
        </LeftContent>
        <RightContent $width={'50%'}>
          <H4>Employer Details</H4>
          <SmallText color={theme.colors.inkBlue}>{userData.employer}</SmallText>
          <SmallText color={theme.colors.inkBlue}>{userData.occupation}</SmallText>
          {userData.employer_address1 ? (
            <>
              <SmallText color={theme.colors.inkBlue}>
                {userData.employer_address1}
                {userData.employer_address2 && `, ${userData.employer_address2}`}
              </SmallText>
              <SmallText color={theme.colors.inkBlue}>
                {userData.employer_city}, {userData.employer_state} {userData.employer_zipcode}
              </SmallText>
            </>
          ) : (
            <SmallText color={theme.colors.inkBlue}>-</SmallText>
          )}
        </RightContent>
      </RowContainer>
      <Spacing $size={32} />
      <RowContainer>
        <LeftContent $width={'50%'}>
          <H4>Preferences</H4>
          <SmallText color={theme.colors.inkBlue}>
            Monthly Budget:{' '}
            {formatNumberAsCurrency(userData.monthly_budget || 0, {
              trailingZeroDisplay: 'auto',
            })}
          </SmallText>
          <SmallText color={theme.colors.inkBlue}>
            Key Issues: {topIssues.join(', ') || '-'}
          </SmallText>
          <SmallText color={theme.colors.inkBlue}>
            Email Cadence:{' '}
            {getObjectDisplayName<IEmailCadence>(userData.email_cadence, emailCadenceDictionary)}
          </SmallText>
        </LeftContent>
      </RowContainer>
    </>
  );
};
