import { FC } from 'react';
import theme from 'config/theme';
import Spacing from 'components/Spacing';
import { GradientBorderCard } from 'components/Cards/GradientBorderCard';
import { IPage } from 'store/pages/types';
import { Title, Description, InnerGradientBox, WhiteBox } from './styled';

interface Props {
  page: IPage;
}

export const ImpactCard: FC<Props> = ({ page }) => {
  return (
    <>
      <WhiteBox />
      <GradientBorderCard
        borderType="full"
        margin="50px 100px 25px 100px"
        withShadow={false}
        overflow="inherit"
        gradient={theme.gradients.fullGradient({ direction: '150deg' })}
      >
        <InnerGradientBox>
          <Title>{page.value}</Title>
          <Spacing $size={4} />
          <Description>{page.page_cta || page.desc}</Description>
          <Spacing $size={12} />
        </InnerGradientBox>
      </GradientBorderCard>
    </>
  );
};
