import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const pagesStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const pagesSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const pagesError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const pagesCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.PAGES_START]: pagesStart,
  [Types.PAGES_SUCCESS]: pagesSuccess,
  [Types.PAGES_ERROR]: pagesError,
  [Types.PAGES_CLEAN_UP]: pagesCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
