import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Row } from 'components/Row';
import Column from 'components/Column';
import ButtonLink from 'components/ButtonLink';
import { Image } from 'components/Image';
import { Body, BodyBig, H4, UtilityText } from 'components/Typography';

export const InputContainer = styled.div<{ $backgroundColor?: string }>`
  background: ${({ theme, $backgroundColor }) => $backgroundColor || theme.colors.white};
  padding: 16px;
`;

export const Container = styled(Column)<{ $backgroundColor?: string }>`
  background: ${({ theme, $backgroundColor }) => $backgroundColor || theme.colors.white};
  padding: 16px;

  @media (max-width: 800px) {
    padding-bottom: 180px;
  }
`;

export const ButtonContainer = styled.div<{ $hiddenOnDesktop?: boolean }>`
  position: sticky;
  bottom: 16px;

  ${({ $hiddenOnDesktop }) => $hiddenOnDesktop && 'display: none'};

  @media (max-width: 801px) {
    display: block;
  }
`;

export const Content = styled.div`
  background: ${({ theme }) => theme.colors.mercury};
  height: 100%;
  width: 100%;
`;

export const MotionContainer = styled(motion.div)`
  background: ${({ theme }) => theme.colors.white};

  @media (min-width: 500px) and (max-width: 800px) {
    left: 10%;
    right: 10%;
  }
  @media (max-width: 500px) {
    left: 0;
    right: 0;
  }
`;

export const MotionBackground = styled(motion.div)`
  background: ${({ theme }) => theme.shadows.black(0.6)};
`;

export const Label = styled.span<{ $isUnderlined?: boolean }>`
  color: ${({ theme }) => theme.colors.jumbo};
  ${({ theme }) => theme.fonts.regular};
  font-size: 16px;
  ${({ $isUnderlined }) => $isUnderlined && `text-decoration: underline; cursor: pointer;`};
`;

export const LeftTitle = styled(BodyBig)<{ $color?: string }>`
  color: ${({ theme, $color }) => $color || theme.colors.white};
  @media (max-width: 800px) {
    line-height: 27px;
  }
`;

export const LeftTitlePartner = styled(LeftTitle)`
  margin-bottom: 16px;
  @media (max-width: 800px) {
    margin-bottom: 13px;
  }
`;

export const OathImage = styled(Image)`
  width: 174px;
`;

export const CheckTitle = styled(UtilityText)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 4px;
  letter-spacing: 0.1em;
`;

export const CheckDescription = styled(Body)`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.fonts.regular};
`;

export const LeftDescription = styled(Body)<{
  focus?: boolean;
  $hideForSmall?: boolean;
  $isCondensed?: boolean;
}>`
  color: ${({ theme, focus }) => focus && theme.colors.electricBlue};
  a {
    color: inherit;
    ${({ theme }) => theme.fonts.regular};
  }
  @media (max-width: 800px) {
    ${({ $hideForSmall }) => $hideForSmall && 'display: none'};
    ${({ $isCondensed }) =>
      $isCondensed &&
      `
      display: block;
      max-height: 60px;
      overflow: hidden;
      position: relative;
      -webkit-mask: linear-gradient(#000 45%, #0000);
      mask: linear-gradient(#000 45%, #0000);
    `};
  }
`;

export const LeftDescriptionOath = styled(H4)`
  color: ${({ theme }) => theme.colors.white};
  margin-top: 9px;
`;

export const LeftInfoContainer = styled(Column)<{
  $backgroundColor: string;
  $isPartnerPage: boolean;
}>`
  background: ${({ $backgroundColor }) => $backgroundColor};
  max-width: 550px;
  min-width: 40%;
  padding: ${({ $isPartnerPage }) => `${$isPartnerPage ? '58px' : '125px'} 40px`};
  align-content: flex-start;
  display: grid;

  @media (max-width: 800px) {
    display: none;
    ${({ $isPartnerPage }) =>
      $isPartnerPage &&
      `
        display: grid;
        max-width: none;
        padding: 20px;
      `};
  }
`;

export const IconWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 2px 4px;
  border-radius: 16px;
  height: fit-content;
  margin-right: 10px;
`;

export const StyledImage = styled(Image)`
  max-height: 180px;
  max-width: 75%;
  @media (max-width: 800px) {
    height: 45px;
  }
`;

export const LeftBanner = styled(Row)`
  margin-bottom: 32px;
  @media (max-width: 800px) {
    margin-bottom: 16px;
  }
`;

export const DescriptionSmall = styled(LeftDescription)`
  @media (min-width: 801px) {
    display: none;
  }
`;

export const DescriptionToggle = styled(ButtonLink)`
  font-size: 15px;
  line-height: 20px;
  ${({ theme }) => theme.fonts.regular};
`;

export const TextsContainer = styled(Column)`
  padding-bottom: 20px;
`;
