import Spacing from 'components/Spacing';
import { IPage } from 'store/pages/types';
import { TopIssueCard } from '../Cards/TopIssueCard';
import { IssuesContainer, TopIssueContainer, TopIssuesRow } from './styled';

export const TopIssues = ({ pages }: { pages: IPage[] }) => {
  return (
    <IssuesContainer>
      <TopIssuesRow $justifyContent="center">
        {pages.map((issue, index) => (
          <TopIssueContainer key={index}>
            <TopIssueCard allowCardMaxWidth={false} issue={issue} />
          </TopIssueContainer>
        ))}
      </TopIssuesRow>
      <Spacing $size={40} />
    </IssuesContainer>
  );
};
