import axios from 'axios';
import { Dispatch } from 'redux';
import FILTERS_TYPES from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getFilters =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(FILTERS_TYPES.filtersStart());
    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_FILTERS}`;

    try {
      const response = await axios.get(url, {
        headers: { ...headers, 'X-API-Key': process.env.REACT_APP_API_KEY },
      });
      dispatch(FILTERS_TYPES.filtersSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(FILTERS_TYPES.filtersError(error.message));
      return Promise.reject(error);
    }
  };
