import { getEventPageSelections } from 'services/storage';
import { IEvent, TicketWithQuantitySelected } from 'store/event/types';

export interface EventSelections {
  custom_amount: number;
  event_tickets: TicketWithQuantitySelected[];
}

export const getEventSelectionsObj = () => {
  const eventSelections = getEventPageSelections();
  if (!eventSelections) {
    return;
  }
  return JSON.parse(eventSelections);
};

export const getTicketCount = (tickets: TicketWithQuantitySelected[]) => {
  return tickets.reduce((acc, ticket) => acc + ticket.quantity_selected, 0);
};

export const getTicketsWithSelections = (tickets: TicketWithQuantitySelected[]) => {
  return tickets.filter(ticket => ticket.quantity_selected > 0);
};

export const getTicketPriceTotal = (ticketSelections: TicketWithQuantitySelected[]) =>
  ticketSelections.reduce((total, ticket) => total + ticket.quantity_selected * ticket.price, 0);

export const getTicketSelectionsFromEventData = (eventData: IEvent) =>
  eventData.tickets.map(ticket => ({
    ...ticket,
    quantity_selected: 0,
  }));
