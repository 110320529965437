import { createActions } from 'reduxsauce';

export interface RecipientsState {
  data: RecipientsData;
  isLoading: boolean;
  error: string;
}

export type RecipientsData = {
  recipients: IRecipient[];
};

export type CampaignDetails = {
  campaign_website?: string;
};

export type Competitor = {
  // not yet in response, needed for hvp
  competitor_name: string;
  raised: string;
  coh: string;
  is_incumbent: boolean;
};

export type MiscIds = {
  photo_id?: string;
  ext_de_url_id?: string;
};

export type Election = {
  display_info?: string;
  election_goal: string;
  state: string;
  projected_cost: string;
  contribution_limit: string;
  is_employer_address_required: boolean;
  ratings: Array<string | null>;
  // pvi not in response, needed for hvp
  competitor?: Competitor; // not yet in response, needed for hvp
  primary_election_date?: string; // not yet in response, needed for hvp
};

export type Race = {
  is_incumbent: boolean;
  is_self_funded: boolean;
  is_pay_to_play_issue: boolean;
  amount_raised: string;
  coh?: string; // not yet in response, needed for hvp
  financials_updated_at?: string; // not yet in response, needed for hvp
  campaign_details: CampaignDetails;
  election: Election;
};

export type IRecipient = {
  recipient_id: string;
  external_id: string;
  misc_ids?: MiscIds;
  recipient_type: string;
  external_status: string;
  impact_score: string;
  recipient_name?: string;
  race?: Race;
};

export type getRecipientProps = {
  amount: number;
  filter_ids?: string[];
  search_str?: string;
  is_public_searchable?: boolean;
  recipient_ids?: string[];
};

export interface RecipientsTypes {
  RECIPIENTS: 'RECIPIENTS';
  RECIPIENTS_START: 'RECIPIENTS_START';
  RECIPIENTS_SUCCESS: 'RECIPIENTS_SUCCESS';
  RECIPIENTS_ERROR: 'RECIPIENTS_ERROR';
  RECIPIENTS_CLEAN_UP: 'RECIPIENTS_CLEAN_UP';
}

const { Types, Creators } = createActions<RecipientsTypes>({
  recipients: ['data'],
  recipientsStart: null,
  recipientsSuccess: ['data'],
  recipientsError: ['error'],
  recipientsCleanUp: null,
});

export { Types };

export default Creators;
