import { useEffect } from 'react';
import theme from 'config/theme';
import Spacing from 'components/Spacing';
import { Footer } from 'components/Footer';
import { Member } from 'components/Member';
import { Bar } from 'components/Header/Bar';
import { Body, Eyebrow, H4 } from 'components/Typography';
import { JoinCommunityCard } from 'components/Cards/JoinCommunityCard';
import { getPathWithRef, scrollToTop } from 'helpers/utils';
import {
  BottomContainer,
  Container,
  MembersContainer,
  MembersRow,
  JoinCommunityContainer,
  TextsContainer,
  Title,
} from './styled';

export const OurTeamScreen = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Bar />
      <Container $topContainer>
        <TextsContainer>
          <Eyebrow>About us</Eyebrow>
          <Spacing $size={20} />
          <Title>
            Oath’s mission is to{' '}
            <Title $color={theme.colors.oathBlue}>empower Democratic donors</Title> to participate
            in our democracy by offering civic education & strategic investment opportunities.
          </Title>
          <Spacing $size={20} />
          <H4>
            Oath provides independent, data-driven recommendations, enabling donors to make
            impactful contributions based on their issue interests, geographic focus, or other
            relevant criteria. Together, we shape a more engaged and effective democracy.
          </H4>
          <Spacing $size={50} />
          <Eyebrow>Our Team</Eyebrow>
          <Spacing $size={8} />
          <Body>
            Our team has decades of campaign finance, big data analytics, and donor advising
            experience.
          </Body>
        </TextsContainer>
        <Spacing $size={20} />
        <MembersContainer>
          <MembersRow $justifyContent="center">
            <Member name="Alan Stiberman" title="Engineering" imgID="Alan" />
            <Member name="Ashley Custer" title="Engineering" imgID="Ashley" />
            <Member name="Brian Derrick" title="Co-founder / CEO" imgID="Brian" />
            <Member name="Emily Berman" title="Client Strategy" imgID="Emily" />
            <Member name="Faye Cruz" title="UI/UX" imgID="Faye" />
            <Member name="Gabriel Boros" title="Data" imgID="Gabe" />
            <Member name="Marty Evans" title="Finance" imgID="Marty" />
            <Member name="Taylor Ourada" title="Co-founder / COO" imgID="Taylor" />
          </MembersRow>
        </MembersContainer>
        <Spacing $size={55} />
      </Container>
      <BottomContainer>
        <Container>
          <JoinCommunityContainer>
            <JoinCommunityCard
              title="Interested in Joining Our Team?"
              cta="CONTACT US"
              link={getPathWithRef('/contact')}
            />
          </JoinCommunityContainer>
          <Spacing $size={55} />
          <TextsContainer>
            <Eyebrow>Our advisors</Eyebrow>
            <Spacing $size={20} />
            <Body>
              We are supported by visionaries and experts who are helping us transform the political
              giving ecosystem.
            </Body>
          </TextsContainer>
          <Spacing $size={20} />
          <MembersContainer>
            <MembersRow $justifyContent="center">
              <Member name="Alex Niemczewski" title="BallotReady" imgID="Alex" />
              <Member name="Ari Weinstein" title="Software Applications Incorporated" imgID="Ari" />
              <Member name="Ben Horwitz" title="Causeway" imgID="Ben" />
              <Member name="Betsy Hoover" title="Higher Ground Labs" imgID="Betsy" />
              <Member name="Elizabeth Cutler" title="Peoplehood + SoulCycle" imgID="Elizabeth" />
              <Member name="Jamie Kantrowitz" title="Strategic Advisor" imgID="Jamie" />
              <Member name="Jill Lyons" title="Maximum Effort" imgID="Jill" />
              <Member
                name="Ning Mosberger-Tang"
                title="1.5°Climate Strategies Group"
                imgID="Ning"
              />
              <Member name="Rob Stavis" title="Bessemer Venture Partners" imgID="Rob" />
              <Member name="Roger Ehrenberg" title="Eberg Capital" imgID="Roger" />
            </MembersRow>
          </MembersContainer>
        </Container>
      </BottomContainer>
      <Footer />
    </>
  );
};
