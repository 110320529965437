const API_ROUTES = {
  ANSWER_QUESTION: '/form/questions/response',
  CANCEL_SUBSCRIPTION: (de_gid: string) => `/user/subscription/${de_gid}/cancel`,
  GET_EVENT: (query_str: string) => `/pages/event${query_str}`,
  GET_FAQS: (type: string) => `/faqs/?faq_type=${type}`,
  GET_IMPACT_REPORT: '/user/impact_report/2024',
  GET_RECOMMENDATIONS: '/user/recommendations',
  GET_FILTERS: '/filters',
  GET_GALLERY_ITEMS: '/media',
  GET_TRANSACTIONS: (user_ref_code: string) =>
    `/user/transactions/?user_ref_code=${encodeURIComponent(user_ref_code)}`,
  GET_QUESTIONS: '/form/questions',
  GET_RECIPIENTS: '/recipients',
  GET_SETS: (query_str?: string) => `/sets/${query_str}`,
  GET_SITE_CONFIG: (section?: string) => `/component_config?${section}`,
  GET_SUBSCRIPTIONS: '/user/subscriptions',
  GET_PAGES: (query_str?: string) => (query_str ? `/pages/donate${query_str}` : '/pages'),
  FUNDING_GAP: '/funding_gap',
  PAY: '/pay',
  PAYMENTS: '/payments',
  REPORT_EMAIL_QUEUE: (report_type: string) => `/report_email_queue/${report_type}`,
  USER_LOGIN: '/user/login',
  USER_PROFILE: '/user/profile',
};

export default API_ROUTES;
