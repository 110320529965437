export const setRefValue = (refValue: string) => {
  sessionStorage.setItem('refValue', refValue);
};

export const setSiteAccessToken = (siteAccessToken: string) => {
  localStorage.setItem('siteAccessToken', siteAccessToken);
};

export const setIsUserOnboarded = (isOnboarded: string) => {
  localStorage.setItem('isOnboarded', isOnboarded);
};

export const setUserName = (username: string) => {
  localStorage.setItem('username', username);
};

export const setSessionID = (id: string) => {
  localStorage.setItem('sessionID', id);
};

export const setRedirectAfterLogin = (screen: string) => {
  sessionStorage.setItem('screen', screen);
};

export const getRefValue = () => sessionStorage.getItem('refValue');

export const getSessionID = () => localStorage.getItem('sessionID');

export const getSiteAccessToken = () => localStorage.getItem('siteAccessToken');

export const getIsUserOnboarded = () => localStorage.getItem('isOnboarded');

export const getUserName = () => localStorage.getItem('username');

export const getRedirectAfterLogin = () => sessionStorage.getItem('screen');

export const clearRedirectAfterLogin = () => sessionStorage.setItem('screen', '');

export const clearAccessToken = () => {
  localStorage.setItem('siteAccessToken', '');
  localStorage.setItem('username', '');
  localStorage.setItem('isOnboarded', '');
};

export const setDonationSelections = (donationSelections: string) => {
  sessionStorage.setItem('donationSelections', donationSelections);
};

export const getDonationSelections = () => sessionStorage.getItem('donationSelections');

export const clearDonationSelections = () => {
  sessionStorage.removeItem('donationSelections');
};

export const setEventPageSelections = (eventPageSelections: string) => {
  sessionStorage.setItem('eventPageSelections', eventPageSelections);
};

export const getEventPageSelections = () => sessionStorage.getItem('eventPageSelections');

export const clearEventPageSelections = () => {
  sessionStorage.removeItem('eventPageSelections');
};
