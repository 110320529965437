import { FC } from 'react';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { Share } from 'components/Share';
import { Row, RowToGrid } from 'components/Row';
import Icon, { IconNames } from 'components/Icon';
import { Body, BodyBig, BodySmall, Eyebrow } from 'components/Typography';
import { formatDate } from 'helpers/date';
import { codeFormatter } from 'helpers/utils';
import { IEvent } from 'store/event/types';
import {
  DescriptionSmall,
  DescriptionToggle,
  LeftBanner,
  LeftDescription,
  StyledImage,
  TextsContainer,
} from './styled';

interface EventProps {
  eventData: IEvent;
  changeSrc: (ev: any) => void;
  showImage: boolean;
  isCondensedDescription: boolean;
  onDescriptionToggle: () => void;
}

export const EventPageDetails: FC<EventProps> = ({
  eventData,
  changeSrc,
  showImage,
  isCondensedDescription,
  onDescriptionToggle,
}) => {
  const textColor = eventData?.page_misc?.page_color_text || theme.colors.white;

  return (
    <>
      <LeftBanner $justifyContent="space-between">
        {showImage && (
          <StyledImage
            alt={`${eventData.value} logo`}
            src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/${ENV_CONFIG().CLOUD_STORAGE.TAGS}/tag_${
              eventData.id
            }.png`}
            onError={changeSrc}
          />
        )}
      </LeftBanner>
      <Row $justifyContent="space-between">
        <Body $color={textColor} $fontType="semiBold">
          {formatDate(eventData.start_datetime, 'dddd, MMMM D, YYYY')}
        </Body>
        <Share color={textColor} />
      </Row>
      <TextsContainer>
        <Spacing $size={6} />
        <BodyBig $color={textColor}>{eventData.value}</BodyBig>
        <Spacing $size={2} />
        <Eyebrow $color={textColor}>{eventData.subdescription}</Eyebrow>
      </TextsContainer>
      <RowToGrid $justifyContent="space-between">
        <TextsContainer $alignContent="flex-start">
          <Eyebrow $color={textColor}>Date & Time</Eyebrow>
          <Spacing $size={8} />
          <Row>
            <div>
              <Icon name={IconNames.Calendar} color={textColor} size={16} />
            </div>
            <Spacing $isVertical $size={4} />
            <Column>
              <Body $color={textColor}>
                {`${formatDate(eventData.start_datetime, 'MMMM D, YYYY ha')}
                                   ${
                                     eventData.end_datetime
                                       ? ` - ${formatDate(eventData.end_datetime, 'ha')}`
                                       : ''
                                   }`}
              </Body>
              <BodySmall $color={textColor}>
                Doors open at {formatDate(eventData.start_datetime, 'ha')}
              </BodySmall>
            </Column>
          </Row>
        </TextsContainer>
        {eventData.is_location_visible && (
          <TextsContainer $alignContent="flex-start">
            <Eyebrow $color={textColor}>Location</Eyebrow>
            <Spacing $size={8} />
            <Row>
              <div>
                <Icon name={IconNames.Location} color={textColor} size={16} />
              </div>
              <Spacing $isVertical $size={4} />
              <Column>
                <Body $color={textColor}>{eventData.location_name}</Body>
                <BodySmall $color={textColor}>{eventData.location_desc}</BodySmall>
              </Column>
            </Row>
          </TextsContainer>
        )}
      </RowToGrid>
      <LeftDescription
        $hideForSmall={true}
        dangerouslySetInnerHTML={{
          __html: codeFormatter(eventData.desc),
        }}
        $color={textColor}
      />
      <DescriptionSmall>
        <Eyebrow $color={textColor}>About this event</Eyebrow>
        <Spacing $size={8} />
        <LeftDescription
          dangerouslySetInnerHTML={{ __html: codeFormatter(eventData.desc) }}
          $isCondensed={isCondensedDescription}
          $color={textColor}
        />
        <Spacing $size={isCondensedDescription ? 0 : 8} />
        <DescriptionToggle onClick={onDescriptionToggle} $color={textColor}>
          {isCondensedDescription ? 'View more' : 'View less'}
        </DescriptionToggle>
      </DescriptionSmall>
    </>
  );
};
