import styled from 'styled-components/macro';

export const MainContainer = styled.div`
  height: 100%;
  display: flex;

  @media (max-width: 800px) {
    display: block;
  }
`;
