import axios from 'axios';
import { Dispatch } from 'redux';
import RECIPIENTS_TYPES, { getRecipientProps } from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getRecipientsAPI = async ({
  amount,
  filter_ids,
  search_str,
  is_public_searchable,
  recipient_ids,
}: getRecipientProps) => {
  let url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_RECIPIENTS}?`;
  if (recipient_ids) {
    url += recipient_ids.map(rid => `recipient_ids=${rid}`).join('&') || 'recipient_ids=';
  }
  if (filter_ids?.length) {
    url += filter_ids.map(filter => `filter_ids=${filter}`).join('&');
  }
  const params = {
    amount,
    search_str,
    is_public_searchable,
  };
  try {
    const response = await axios.get(url, {
      headers: { ...headers, 'X-API-Key': process.env.REACT_APP_API_KEY },
      params,
    });
    return Promise.resolve(response);
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getRecipients =
  ({ amount, filter_ids, search_str, is_public_searchable, recipient_ids }: getRecipientProps) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(RECIPIENTS_TYPES.recipientsStart());

    try {
      const response = await getRecipientsAPI({
        amount,
        filter_ids,
        search_str,
        is_public_searchable,
        recipient_ids,
      });
      dispatch(RECIPIENTS_TYPES.recipientsSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(RECIPIENTS_TYPES.recipientsError(error.message));
      return Promise.reject(error);
    }
  };
