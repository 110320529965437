import { createActions } from 'reduxsauce';
import { IPage } from 'store/pages/types';

export interface SetsState {
  data: SetsData;
  isLoading: boolean;
  error: string;
}

export type SetsData = {
  bottom_section: boolean;
  display_fundraising_bar: boolean;
  end_date: string;
  fundraising_donation_count: number;
  fundraising_goal: string;
  fundraising_raised: string;
  is_enabled: boolean;
  latest_fundraising_location: string;
  querystring: string;
  set_desc: string;
  set_desc_2: string;
  set_desc_3: string;
  set_id: string;
  set_misc: IMisc;
  set_pages: FundraisingPage[];
  set_title: string;
  set_title_2: string;
  set_title_3: string;
  set_type: string;
  start_date: string;
  pages: IPage[];
};

export type IMisc = {
  share_custom_1: string;
  donate_custom_1: string;
};

export type ITrendingIssue = {
  id: number;
  name: string;
  redirect_url: string;
  raised: string;
  fundraising_goal: string;
  cta: string;
};

export type FundraisingPage = {
  latest_fundraising_transactions: FundraisingTransaction[];
  learn_more_link: string;
  page_fundraising_donation_count: number;
  page_fundraising_goal: string;
  page_fundraising_raised: string;
  page_id: number;
};

export type FundraisingTransaction = {
  amount: string;
  city: string;
  state: string;
  transaction_date: string;
};

export interface SetsTypes {
  SETS: 'SETS';
  SETS_START: 'SETS_START';
  SETS_SUCCESS: 'SETS_SUCCESS';
  SETS_ERROR: 'SETS_ERROR';
  SETS_CLEAN_UP: 'SETS_CLEAN_UP';
}

const { Types, Creators } = createActions<SetsTypes>({
  sets: ['data'],
  setsStart: null,
  setsSuccess: ['data'],
  setsError: ['error'],
  setsCleanUp: null,
});

export { Types };

export default Creators;
