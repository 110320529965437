import { createActions } from 'reduxsauce';
import { IPage } from 'store/pages/types';

export interface EventState {
  data: IEvent;
  isLoading: boolean;
  error: string;
}

export type EventData = {
  event: IEvent[];
};

export type ITicket = {
  event_id: string;
  id: string;
  name: string;
  description: string;
  price: number;
  quantity: number;
  quantity_remaining: number;
  status: 'ACTIVE' | 'REMOVED';
};

export interface TicketWithQuantitySelected extends ITicket {
  quantity_selected: number;
}

export type IEvent = IPage & {
  event_id: string;
  start_datetime: string;
  subdescription: string;
  location_name: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  start_time: string;
  end_datetime: string;
  post_donate_msg: string;
  email_msg: string;
  tickets: ITicket[];
  doors_open_time: string;
  is_location_visible: boolean;
  location_desc: string;
};

export interface EventTypes {
  EVENT: 'EVENT';
  EVENT_START: 'EVENT_START';
  EVENT_SUCCESS: 'EVENT_SUCCESS';
  EVENT_ERROR: 'EVENT_ERROR';
  EVENT_CLEAN_UP: 'EVENT_CLEAN_UP';
}

export type getEventProps = {
  urlPartner?: string;
};

const { Types, Creators } = createActions<EventTypes>({
  event: ['data'],
  eventStart: null,
  eventSuccess: ['data'],
  eventError: ['error'],
  eventCleanUp: null,
});

export { Types };

export default Creators;
