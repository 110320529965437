import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import theme from 'config/theme';
import { Row } from 'components/Row';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import Icon, { IconNames } from 'components/Icon';
import { showToast, Toast } from 'components/Toast';
import { ButtonBrand } from 'components/ButtonBrand';
import { BodySmall, H2Text } from 'components/Typography';
import { ProfileCard } from 'components/Cards/ProfileCard';
import { TextField } from 'components/FinalForm/TextField';
import { UnsavedModal } from 'components/Modal/UnsavedModal';
import { formatNumberAsCurrency, getPathWithRef, wait } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useAuth } from 'hooks/auth';
import { getAllPages } from 'selectors/pages';
import { getUserData } from 'selectors/user';
import { getPages } from 'store/pages/actions';
import { getRecommendations } from 'store/recommendations/actions';
import { getUserProfile, updateUserProfile } from 'store/user/actions';
import { TagSelectField } from './TagSelectField';
import { PersonalDetails } from './PersonalDetails';
import { EmploymentDetails } from './EmploymentDetails';
import { CadenceSelectField } from './CadenceSelectField';
import { CardsContainer, RightContent } from '../styled';
import { BackText, EditProfileContainer, HeaderSection, SectionGrid } from './styled';

const backIconStyles = {
  background: theme.shadows.black(0.1),
  borderRadius: '30px',
  marginRight: '8px',
  WebkitTransform: 'scaleX(-1)',
  transform: 'scaleX(-1)',
};

const getBudgetRequestString = (budget: string) => budget.replace(/[$,]/g, '');

const getFormErrors = (err: AxiosError) => {
  try {
    // @ts-ignore:next-line
    const errArray = err.response?.data?.detail;
    if (errArray && errArray.length > 0) {
      return errArray.reduce((formErrors, errorDetails) => {
        return {
          ...formErrors,
          [errorDetails.loc[1]]: errorDetails.msg,
        };
      }, {});
    }
    return { [FORM_ERROR]: err.message };
  } catch (e) {
    return { [FORM_ERROR]: err.message || 'Error: profile failed to update.' };
  }
};

export const ProfileEditScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const auth0Requested = useAuth();
  const [unsavedModalVisible, showUnsavedModal] = useState(false);
  const { userData, allPages } = useAppSelector(state => ({
    userData: getUserData(state),
    allPages: getAllPages(state),
  }));

  useEffect(() => {
    if ((auth0Requested && !userData) || !allPages) {
      dispatch(getUserProfile());
      dispatch(getRecommendations());
      dispatch(getPages({}));
    }
  }, [auth0Requested]);

  const updateUser = data => {
    return dispatch(updateUserProfile(data));
  };

  const onSubmit = values => {
    const updatedValues = Object.keys(userData).reduce((acc, key) => {
      const value = key === 'monthly_budget' ? getBudgetRequestString(values[key]) : values[key];
      if (userData[key] !== value) {
        return {
          ...acc,
          [key]: value || '',
        };
      }
      return acc;
    }, {});

    if (!Object.keys(updatedValues).length) {
      showToast('Profile not updated. No values changed', 'warning');
      return;
    }

    return new Promise(resolve => {
      updateUser(updatedValues)
        .then(response => {
          showToast('Profile successfully updated!', 'success');
          resolve(response);
        })
        .catch(error => {
          showToast(
            'Profile failed to update.  Please check form validation errors or contact support.',
            'error'
          );
          resolve(getFormErrors(error));
          window.scrollTo({
            top: 100,
            behavior: 'smooth',
          });
        });
    });
  };

  const formatBudget = value => {
    const newValue = value.replace('$', '');
    // eslint-disable-next-line
    return formatNumberAsCurrency(newValue.replaceAll(/[^0-9\.]/g, ''), {
      trailingZeroDisplay: 'auto',
    });
  };

  const parseBudget = value => {
    return value.replace('$', '');
  };

  const handleGoBack = (saveData: boolean) => {
    if (saveData) {
      document.getElementById('profile-form-submit')?.click();
      wait(2000).then(() => navigate(getPathWithRef('..')));
    } else {
      navigate(getPathWithRef('..'));
    }
  };

  const openModal = form => {
    const isDirty = form.getState().dirty;
    if (isDirty) {
      showUnsavedModal(true);
    } else {
      navigate(getPathWithRef('..'));
    }
  };

  return (
    <EditProfileContainer>
      <Toast />
      <UnsavedModal
        isOpen={unsavedModalVisible}
        onClose={() => showUnsavedModal(false)}
        onSubmit={handleGoBack}
      />
      <CardsContainer>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            ...userData,
            monthly_budget: formatNumberAsCurrency(userData.monthly_budget, {
              trailingZeroDisplay: 'auto',
            }),
            issues: userData.issues || [],
          }}
          render={({ handleSubmit, form }) => (
            <>
              <HeaderSection $width={'33%'}>
                <Link
                  onClick={() => openModal(form)}
                  relative="path"
                  style={{ textDecoration: 'none' }}
                >
                  <Row $alignItems="center">
                    <Icon
                      name={IconNames.ChevronRight}
                      color={theme.colors.black}
                      size={30}
                      style={backIconStyles}
                    />
                    <BackText>Go back</BackText>
                  </Row>
                </Link>
                <Spacing $size={24} />
                <div>
                  <div>
                    <BodySmall $color={theme.shadows.black(0.5)}>Edit profile</BodySmall>
                  </div>
                  <H2Text>Account Info & Settings</H2Text>
                </div>
              </HeaderSection>
              <RightContent $width={'66%'}>
                <form onSubmit={handleSubmit}>
                  <ProfileCard smallTitle isInverted title="Your Budget This Month">
                    <SectionGrid>
                      <TextField
                        name="monthly_budget"
                        type="text"
                        format={formatBudget}
                        formatOnBlur={true}
                        parse={parseBudget}
                        label="Every month"
                        isLabelBelow={true}
                      />
                    </SectionGrid>
                  </ProfileCard>
                  <Spacing $size={35} />
                  <ProfileCard smallTitle isInverted title="Your Top Issues">
                    <TagSelectField
                      getState={form.getState}
                      allPages={allPages}
                      userId={userData.id}
                    />
                  </ProfileCard>
                  <Spacing $size={35} />
                  <ProfileCard smallTitle isInverted title="Your Recommendation Cadence">
                    <SectionGrid>
                      <CadenceSelectField getState={form.getState} userId={userData.id} />
                    </SectionGrid>
                  </ProfileCard>
                  <Spacing $size={35} />
                  <ProfileCard smallTitle isInverted title="Personal Details">
                    <SectionGrid>
                      <PersonalDetails selectedCountry={form.getState().values.country} />
                    </SectionGrid>
                  </ProfileCard>
                  <Spacing $size={35} />
                  <ProfileCard smallTitle isInverted title="Employment Details">
                    <SectionGrid>
                      <EmploymentDetails change={form.change} />
                    </SectionGrid>
                  </ProfileCard>
                  <Spacing $size={35} />
                  <ButtonBrand
                    id="profile-form-submit"
                    type="submit"
                    disabled={!form.getState().dirty}
                    $isProcessing={form.getState().submitting}
                  >
                    {form.getState().submitting ? 'Saving...' : 'Update'}
                  </ButtonBrand>
                </form>
              </RightContent>
            </>
          )}
        />
      </CardsContainer>
    </EditProfileContainer>
  );
};
