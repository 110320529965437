import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { IFilter, FiltersState } from 'store/filters/types';

export const getFiltersState = (state: RootState): FiltersState => state.filters;

export const getFiltersData = createSelector(
  [getFiltersState],
  (state: FiltersState): IFilter[] => state?.data?.filters
);

export const getTopFilters = createSelector(
  [getFiltersState],
  (state: FiltersState): IFilter[] => state?.data?.filters?.filter(filter => filter?.is_top_filter)
);
