import styled from 'styled-components/macro';
import Column from 'components/Column';
import { Row } from 'components/Row';

export const Container = styled(Column)`
  padding: 16px 0;
  gap: 8px;
`;

export const TicketContainer = styled(Row)<{ $disabled?: boolean }>`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  color: ${({ theme }) => theme.colors.inkBlue};
  padding: 10px;
  ${({ theme, $disabled }) =>
    $disabled &&
    `
    background: ${theme.colors.softGray};
    border-color: ${theme.colors.softGray};
    color: ${theme.colors.gray};
  `}
`;

export const QuantityContainer = styled(Row)`
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.platinum};
  align-self: flex-end;
  padding: 5px;
`;

export const QuantityButton = styled.div<{ $disabled?: boolean }>`
  background: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.platinum : theme.colors.oathBlue};
  color: ${({ theme, $disabled }) => ($disabled ? theme.colors.inkBlue : theme.colors.white)};
  border-radius: 3px;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
`;

export const TicketAmountRow = styled(Row)`
  flex-direction: column;
`;
