import { DesktopLeftContainer } from 'components/DesktopLeftContainer';
import { MainContainer } from 'screens/Main/styled';
import { CheckoutContainer } from './styled';
import { CheckoutForm } from './Form';
import { ContributionRules } from 'components/Slate/ContributionRules';
import { noop } from 'helpers/utils';
import Spacing from 'components/Spacing';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { EventSelections } from 'helpers/event';
import { PaymentsData } from 'store/payments/types';
import { IUserData } from 'store/user/types';
import { FC } from 'react';

interface Props {
  candidates: CandidateType[];
  donationAmount: number;
  eventSelections?: EventSelections;
  payments: PaymentsData[];
  user?: IUserData;
}

export const CheckoutFlow: FC<Props> = ({
  candidates,
  donationAmount,
  eventSelections,
  payments,
  user,
}) => {
  return (
    <MainContainer>
      <DesktopLeftContainer setRefWidthValue={noop} />
      <CheckoutContainer>
        <CheckoutForm
          candidates={candidates}
          donationAmount={donationAmount}
          eventSelections={eventSelections}
          payments={payments}
          user={user}
        />
        <Spacing $size={70} />
        <ContributionRules />
        <Spacing $size={48.5} />
      </CheckoutContainer>
    </MainContainer>
  );
};
