import { FC, ReactNode } from 'react';
import Slider from 'rc-slider';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { ButtonBrand } from 'components/ButtonBrand';
import { Body, BodyBig, BodySmall, Eyebrow } from 'components/Typography';
import { formatTime } from 'helpers/date';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import {
  abbreviateNum,
  codeFormatter,
  getPathWithRef,
  getUrlParams,
  limitChars,
} from 'helpers/utils';
import { getSessionID } from 'services/storage';
import { IPage } from 'store/pages/types';
import {
  Leyend,
  StyledImage,
  Container,
  Content,
  FadeBottom,
  TopIssueWrapper,
  Line,
} from './styled';

interface Props {
  allowCardMaxWidth?: boolean;
  issue: IPage;
  width?: string;
  isBottomFaded?: boolean;
  children?: ReactNode;
  fullHeight?: boolean;
}

const sliderStyles = {
  rail: {
    height: 12,
    borderRadius: 12,
  },
  track: {
    background: `linear-gradient(to right, ${theme.colors.darkGreen}, ${theme.colors.oathBlue})`,
    height: 12,
  },
  handle: {
    display: 'none',
  },
};

export const TopIssueCard: FC<Props> = ({
  allowCardMaxWidth = true,
  issue,
  width,
  isBottomFaded,
  children,
  fullHeight,
}) => {
  const { urlPartner, urlRef } = getUrlParams();
  const handleDonate = () => {
    logFirebaseEvent('donate_intent_set', {
      timestamp: new Date().toString(),
      session_id: getSessionID(),
      ref_code: urlRef,
      set_query_str: urlPartner,
      tag_id: issue.id,
    });
  };

  return (
    <TopIssueWrapper $width={width} $fullHeight={fullHeight}>
      <Container $isSmallCard={allowCardMaxWidth && !width} $fullHeight={fullHeight}>
        <Content>
          <Eyebrow $color={theme.colors.inkBlue}>{issue.page_category || 'TOP ISSUE'}</Eyebrow>
          <Spacing $size={8} />
          <Row>
            <StyledImage
              alt={`${issue.value} graphic`}
              src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/tags/icon/tag_${issue.id}.png`}
            />
            <Spacing $isVertical $size={8} />
            <BodyBig>{issue.value}</BodyBig>
          </Row>
          <Spacing $size={16} />
          <Row $justifyContent="space-between" $alignItems="flex-end">
            <Body>${abbreviateNum(parseInt(issue.raised))} Raised</Body>
            <Body>Goal ${abbreviateNum(parseInt(issue.fundraising_goal))}</Body>
          </Row>
          <Slider
            min={0}
            max={100}
            value={(parseInt(issue.raised) * 100) / parseInt(issue.fundraising_goal)}
            styles={sliderStyles}
          />
          <Spacing $size={8} />
          <Leyend>Updated at {formatTime(new Date())}</Leyend>
          {children ? (
            <>{children}</>
          ) : (
            <>
              <Spacing $size={16} />
              <ButtonBrand
                onClick={handleDonate}
                href={getPathWithRef(`/donate?p=${issue.query_str}`)}
                target="_blank"
              >
                Donate
              </ButtonBrand>
              <Spacing $size={16} />
              {!!issue.page_cta && (
                <>
                  <Line />
                  <Eyebrow $color={theme.colors.inkBlue}>WHY THIS MATTERS</Eyebrow>
                  <Spacing $size={4} />
                  <Body
                    dangerouslySetInnerHTML={{
                      __html: codeFormatter(limitChars(issue.page_cta, 155)),
                    }}
                  />
                  <Spacing $size={4} />
                </>
              )}
              <Link
                href={getPathWithRef(issue.learn_more_link || `/donate?p=${issue.query_str}`)}
                target="_blank"
              >
                <BodySmall>Learn More</BodySmall>
              </Link>
              <Spacing $size={8} />
            </>
          )}
        </Content>
        {isBottomFaded && <FadeBottom />}
      </Container>
    </TopIssueWrapper>
  );
};
