import { createActions } from 'reduxsauce';

export interface FiltersState {
  data: FiltersData;
  isLoading: boolean;
  error: string;
}

export type FiltersData = {
  filters: IFilter[];
};

export type IFilter = {
  filter_id: string;
  filter_name: string;
  filter_type: string;
  is_top_filter: boolean;
};

export interface FiltersTypes {
  FILTERS: 'FILTERS';
  FILTERS_START: 'FILTERS_START';
  FILTERS_SUCCESS: 'FILTERS_SUCCESS';
  FILTERS_ERROR: 'FILTERS_ERROR';
  FILTERS_CLEAN_UP: 'FILTERS_CLEAN_UP';
}

const { Types, Creators } = createActions<FiltersTypes>({
  filters: ['data'],
  filtersStart: null,
  filtersSuccess: ['data'],
  filtersError: ['error'],
  filtersCleanUp: null,
});

export { Types };

export default Creators;
