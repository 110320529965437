import { createActions } from 'reduxsauce';
import { ProcessingRedirectOptions } from './constants';

export interface PagesState {
  data: PagesData;
  fullListData: PagesData;
  isLoading: boolean;
  error: string;
}

export type PagesData = {
  pages: IPage[];
};

export type IPageType = 'LIST' | 'COMPACT' | 'MEDIA';

export type IPage = {
  id: number;
  donation_amounts: number[];
  value: string;
  desc: string;
  page_misc: {
    page_background: string;
    page_color_text: string;
  };
  page_cta: string;
  is_enabled: boolean;
  impact_score_visibility: boolean;
  query_str: string;
  page_type: IPageType;
  processing_redirect: ProcessingRedirect;
  page_rating: number;
  client_id: number;
  de_page: string;
  page_category: string;
  display_fundraising_bar: string;
  display_community_tracker: string;
  fundraising_goal: string;
  learn_more_link: string;
  raised: string;
  set_query_string: string;
  whitelabeled: boolean;
  paused_misc: object;
  status: string;
  owner: string;
  recipient_ids: string[];
  event_id: string;
};

export type ProcessingRedirect =
  | ProcessingRedirectOptions.DE_GENERAL
  | ProcessingRedirectOptions.EN_GENERAL
  | ProcessingRedirectOptions.DE_OATH;

export type getPagesProps = {
  urlPartner?: string;
};

export interface PagesTypes {
  PAGES: 'PAGES';
  PAGES_START: 'PAGES_START';
  PAGES_SUCCESS: 'PAGES_SUCCESS';
  PAGES_ERROR: 'PAGES_ERROR';
  PAGES_CLEAN_UP: 'PAGES_CLEAN_UP';
}

const { Types, Creators } = createActions<PagesTypes>({
  pages: ['data'],
  pagesStart: null,
  pagesSuccess: ['data'],
  pagesError: ['error'],
  pagesCleanUp: null,
});

export { Types };

export default Creators;
