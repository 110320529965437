import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { IEvent, EventState } from 'store/event/types';

export const getEventState = (state: RootState): EventState => state.event;

export const getEventData = createSelector(
  [getEventState],
  (state: EventState): IEvent => state?.data
);
