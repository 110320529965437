import { FC } from 'react';
import { Row } from 'components/Row';
import Loader from 'components/Loader';
import { Toast } from 'components/Toast';
import Spacing from 'components/Spacing';
import { trackPixel } from 'helpers/pixel';
import { IPage } from 'store/pages/types';
import { IRecipient } from 'store/recipients/types';
import { CustomSplit } from './CustomSplit';
import { ContributionRules } from './ContributionRules';
import { DonationTotal } from './DonationAmount/DonationTotal';
import { Candidates, CandidateType } from './Candidates/Candidates';
import { DonateButton, SlateContainer } from './styled';

interface Props {
  allPages: IPage[];
  candidates: CandidateType[];
  onSelectCandidates: (updatedCandidates: CandidateType[]) => void;
  recipients: IRecipient[];
  selectedDonationAmount?: number;
  onDonateClick: () => void;
  onUserDonationTotalChange: (value?: number) => void;
  setCandidates: React.Dispatch<React.SetStateAction<CandidateType[]>>;
  setSelectedDonationAmount: React.Dispatch<React.SetStateAction<number | undefined>>;
  showCustomSplit: boolean;
}

export const CompactSlate: FC<Props> = ({
  allPages,
  candidates,
  onDonateClick,
  onSelectCandidates,
  onUserDonationTotalChange,
  recipients,
  selectedDonationAmount,
  setCandidates,
  setSelectedDonationAmount,
  showCustomSplit,
}) => {
  const onDonateButtonClick = () => {
    trackPixel('donation');
    onDonateClick();
  };

  if (!allPages || !recipients) {
    return <Loader width={60} height={60} />;
  }

  return (
    <SlateContainer>
      <Toast />
      <Candidates
        candidates={candidates}
        recipientCount={recipients.length}
        setCandidates={onSelectCandidates}
      />
      <DonationTotal
        donationAmounts={allPages[0].donation_amounts}
        selectedDonationAmount={selectedDonationAmount}
        setSelectedDonationAmount={onUserDonationTotalChange}
      >
        <>
          <Row $justifyContent="space-around">
            <DonateButton className="split-screen" onClick={onDonateButtonClick}>
              DONATE
            </DonateButton>
          </Row>
          <CustomSplit
            candidates={candidates}
            donationTotal={selectedDonationAmount}
            setCandidates={setCandidates}
            setDonationTotal={setSelectedDonationAmount}
            showCustomSplit={showCustomSplit}
            resetDonationTotal={onUserDonationTotalChange}
          />
        </>
      </DonationTotal>
      <Spacing $size={147} />
      <ContributionRules />
      <Spacing $size={14.5} />
    </SlateContainer>
  );
};
