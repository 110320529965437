import BigNumber from 'bignumber.js';

export const RETIRED_OR_UNEMPLOYED = 'No employer';

export const noop = () => {
  /* do nothing */
};

export const splitAmountRounded = (totalAmount, numberOfPeople) => {
  /* function to split totalAmount between numberOfPeople so that each person gets an amount rounded 2 decimals */
  const amountPerPerson = new BigNumber(totalAmount)
    .times(100)
    .dividedBy(numberOfPeople)
    .floor()
    .dividedBy(100);
  const remainingAmount = new BigNumber(totalAmount).minus(amountPerPerson.times(numberOfPeople));

  return Array.from({ length: numberOfPeople }, (_, index) => {
    if (index < remainingAmount.times(100).toNumber()) {
      return amountPerPerson.plus(0.01).toNumber();
    }
    return amountPerPerson.toNumber();
  });
};

export const createSessionID = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  return result;
};

export const getUrlParams = () => {
  const searchParams = getUrlSearchParamsObj();

  return {
    urlRef: searchParams?.get('ref')?.replace('/', ''),
    urlPartner: searchParams?.get('p')?.replace('/', ''),
    urlIssue: searchParams?.get('issue')?.replace('/', ''),
    urlAmount: searchParams?.get('amount')?.replace('/', ''),
    urlTags: searchParams.getAll('tags'),
    urlDonateAgain: searchParams?.get('donate_again')?.replace('/', ''),
    urlEvent: searchParams?.has('event'),
  };
};

export const numberWithDot = (x: number) => {
  const withComma = x?.toString().replace(',', '.');

  return withComma?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || ' ...';
};

export function wait(timeout: number) {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
}

export const formatNumberAsCurrency = (num: number, options: object = {}) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    //@ts-ignore
    trailingZeroDisplay: 'stripIfInteger',
    ...options,
  }).format(num);
};

export const formatNumber = (num: number, options: object = {}) => {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    //@ts-ignore
    trailingZeroDisplay: 'stripIfInteger',
    ...options,
  }).format(num);
};

export const getPathWithRef = (path: string) => {
  const { urlRef } = getUrlParams();
  const char = path.includes('?') ? '&' : '?';
  const ref = urlRef ? `${char}ref=${urlRef}` : '';
  return `${path}${ref}`;
};

export const abbreviateNum = (num: number) => {
  const value = Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(num);

  return isNaN(parseFloat(value)) ? '0' : value;
};

export const roundNumberToHundredths = (num: number | string) => {
  const numFloat = typeof num === 'string' ? Number.parseFloat(num) : num;
  return Math.floor(numFloat * 100) / 100;
};

export const getUrlSearchParamsObj = () => new URL(window.location.href).searchParams;

export const getUrlSearchParamsStr = () => getUrlSearchParamsObj().toString();

export const limitChars = (value: string, limit: number) => {
  if (value?.length > limit) {
    return `${value.substring(0, limit)}...`;
  } else {
    return value;
  }
};

export function getObjectDisplayName<T extends object>(key: string, obj: T) {
  if (Object.keys(obj).includes(key)) {
    return obj[key];
  }
  return '-';
}

export const isKeyInObject = (key: string, obj: object) => Object.keys(obj).includes(key);

export const required = (value: any) => (value ? undefined : 'Required');

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

//Dashboard returns different symbol when add code to rich text
export const codeFormatter = (text: string) => {
  return text?.replaceAll('&lt;', '<')?.replaceAll('&gt;', '>');
};

export function getRowLength<T extends object>(size: number, recipients: T[]) {
  const newArr = Array<T[]>();
  // TIP: For mobile and tablet make different lines of "length" items each
  for (let i = 0; i < Math.ceil(recipients?.length / size); i++) {
    newArr.push(recipients?.slice(i * size, i * size + size));
  }
  return newArr;
}

export const getIsRetiredOrUnemployed = (employer: string, occupation: string) => {
  return employer === RETIRED_OR_UNEMPLOYED && occupation === RETIRED_OR_UNEMPLOYED;
};

export const getFormattedDate = (date?: string | null) => {
  if (date && date !== 'NA') {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  } else {
    return date;
  }
};
