import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Outlet, useNavigate } from 'react-router-dom';
import Loader from 'components/Loader';
import { Bar } from 'components/Header/Bar';
import { getPathWithRef } from 'helpers/utils';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useRedirectAfterLogin } from 'hooks/useRedirectAfterLogin';
import { getUserState } from 'selectors/user';
import { getPagesState } from 'selectors/pages';
import { setIsUserOnboarded, setUserName } from 'services/storage';
import { getPages } from 'store/pages/actions';
import { getUserProfile } from 'store/user/actions';
import { getTransactions } from 'store/transactions/actions';
import { getRecommendations } from 'store/recommendations/actions';
import { datadogLogs } from '@datadog/browser-logs';
import { useAuth } from 'hooks/auth';
import { getSubscriptions } from 'store/subscriptions/actions';
import { getMessageFromAxiosError } from 'helpers/errors';
import { getImpactReport } from 'store/impactReport/actions';

// const UNAUTHORIZED_ERROR_STATUS = 401;

export const ProfileScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading, isAuthenticated } = useAuth0();
  const auth0Requested = useAuth();
  const [isAllDataRequested, setIsAllDataRequested] = useState(false);
  const { screenToRedirect, handleRedirect } = useRedirectAfterLogin();
  const { userState, pagesState } = useAppSelector(state => ({
    userState: getUserState(state),
    pagesState: getPagesState(state),
  }));

  const handleApiError = (error: AxiosError) => {
    // if (error.response?.status === UNAUTHORIZED_ERROR_STATUS) {
    //   navigate('/login');
    //   return;
    // }
    // navigate('/error');
    // THIS WAS CAUSING A BUG FOR NEW USERS GETTING ERROR RESPONSE
    // FROM GET /recommendations
    // HANDLE ERROR SILENTLY FOR NOW
    console.warn(error.message);
    datadogLogs.logger.warn(
      'Browser error occurred on profile page',
      {},
      new Error(getMessageFromAxiosError(error))
    );
    return;
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!isAuthenticated) {
      navigate(getPathWithRef('/login'));
      return;
    }
    if (screenToRedirect) {
      handleRedirect(screenToRedirect);
      return;
    }
    if (!userState.data) {
      return;
    }
    if (!userState.data.is_onboarded) {
      navigate(getPathWithRef('/questions'));
      return;
    }
    if (userState?.data?.id) {
      logFirebaseEvent('profile_view', {
        timestamp: new Date().toString(),
        user_id: userState?.data?.id,
      });
      return;
    }
  }, [isLoading, isAuthenticated, userState.data]);

  useEffect(() => {
    if (auth0Requested && isAuthenticated) {
      Promise.allSettled([
        dispatch(getPages({})),
        dispatch(getRecommendations()),
        dispatch(getUserProfile()),
        dispatch(getImpactReport()),
      ]).catch(error => {
        handleApiError(error);
      });
    }
  }, [isAuthenticated, auth0Requested]);

  useEffect(() => {
    if (!userState.isLoading && userState.data && !isAllDataRequested) {
      const isOnboarded = userState.data.is_onboarded;
      dispatch(getTransactions(userState.data.ref_code)).catch(error => {
        handleApiError(error);
      });
      dispatch(getSubscriptions()).catch(error => {
        handleApiError(error);
      });
      setIsAllDataRequested(true);
      setIsUserOnboarded(isOnboarded.toString());
      setUserName(userState.data?.first_name);
      if (!isOnboarded) navigate(getPathWithRef('/questions'));
    }
  }, [userState]);

  return (
    <>
      <Bar />
      {!userState.data || !pagesState.data || isLoading ? (
        <div className="loader-center">
          <Loader width={60} height={60} />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};
