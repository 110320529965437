import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const filtersStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const filtersSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const filtersError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const filtersCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.FILTERS_START]: filtersStart,
  [Types.FILTERS_SUCCESS]: filtersSuccess,
  [Types.FILTERS_ERROR]: filtersError,
  [Types.FILTERS_CLEAN_UP]: filtersCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
