import theme from 'config/theme';
import { Row } from 'components/Row';
import Icon, { IconNames } from 'components/Icon';
import { PopupModal } from 'components/PopupModal';
import { MotionButton } from 'components/Header/styled';
import { useAppSelector } from 'helpers/hooks';
import { useShareUrl } from 'hooks/useShareUrl';
import { getUserState } from 'selectors/user';
import { ShareText } from './styled';

const motionButtonStyle = {
  padding: '0',
  display: 'flex',
  alignItems: 'center',
  zIndex: '0',
};

export const Share = ({ color }: { color?: string }) => {
  const userState = useAppSelector(getUserState);
  const { handleShare, isPopupOpen } = useShareUrl(userState.data?.ref_code, true);

  return (
    <>
      <PopupModal autoClose={true} isSelected={isPopupOpen} title="Copied to Clipboard" />
      <Row $alignItems="flex-end">
        <MotionButton onClick={handleShare} whileTap={{ scale: 0.9 }} style={motionButtonStyle}>
          <Icon name={IconNames.Share} color={color || theme.colors.white} size={16} />
          <ShareText $color={color}>Share</ShareText>
        </MotionButton>
      </Row>
    </>
  );
};
