import { FC, useState } from 'react';
import { Row } from 'components/Row';
import Spacing from 'components/Spacing';
import { Body } from 'components/Typography';
import { PopupModal } from 'components/PopupModal';
import Icon, { IconNames } from 'components/Icon';
import { CandidateCard } from 'components/Cards/CandidateCard';
import { HiddenContainer } from './styled';
import { CandidateType } from 'components/Slate/Candidates/Candidates';

interface Props {
  candidates: CandidateType[];
  handleCandidatesSelected: (index: number, check: boolean) => void;
  showInDesktop: boolean;
}

export const CandidatesSection: FC<Props> = ({
  candidates,
  showInDesktop,
  handleCandidatesSelected,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <HiddenContainer $showInDesktop={showInDesktop}>
      <PopupModal
        autoClose={false}
        closePopup={() => setIsPopupOpen(false)}
        isSelected={isPopupOpen}
        title="What’s the Impact Score?"
        description="Oath’s Impact Score is calculated from dozens of data points across three metrics: Competitiveness, Stakes, and Financial Need."
      />
      <Spacing $size={16} />
      <Row $justifyContent="space-between">
        <Body>Candidate</Body>
        <Row>
          <Body>Impact Score</Body>
          <Spacing $isVertical $size={8} />
          <Icon
            name={IconNames.HelpCircle}
            size={20}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsPopupOpen(true);
            }}
          />
        </Row>
      </Row>
      <Spacing $size={8} />
      {candidates.map((item, i) => (
        <CandidateCard
          key={i}
          index={i}
          item={item}
          selectItem={handleCandidatesSelected}
          impactScoreVisibility
        />
      ))}
    </HiddenContainer>
  );
};
