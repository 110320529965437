import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { IPage, PagesState } from 'store/pages/types';

export const getPagesState = (state: RootState): PagesState => state.pages;

export const getAllPages = createSelector(
  [getPagesState],
  (state: PagesState): IPage[] => state?.data?.pages
);

export const getDonationAmounts = createSelector([getPagesState], (state: PagesState): number[] =>
  state?.data?.pages?.length > 0 && state?.data?.pages[0].donation_amounts
    ? state?.data?.pages[0].donation_amounts
    : [10, 25, 50, 100]
);
