import { FC, useRef, useState, useEffect } from 'react';
import theme from 'config/theme';
import Loader from 'components/Loader';
import Spacing from 'components/Spacing';
import { Tickets } from 'components/Tickets';
import { ButtonBrand } from 'components/ButtonBrand';
import { BodySmall, H4 } from 'components/Typography';
import { CandidateCard } from 'components/Cards/CandidateCard';
import { getUrlParams } from 'helpers/utils';
import { IPageType } from 'store/pages/types';
import { HR } from './CustomSplit/styled';
import { Candidates, CandidateType } from './Candidates/Candidates';
import { ContributionRules } from './ContributionRules';
import { Container, Content } from '../DesktopLeftContainer/styled';
import { useAppSelector } from 'helpers/hooks';
import { getRecipientsState } from 'selectors/recipients';
import { CandidatesContainer } from './styled';
import { Row } from 'components/Row';
import Icon, { IconNames } from 'components/Icon';
import { Title } from 'components/ListTags/styled';
import { PopupModal } from 'components/PopupModal';
import { IEvent, TicketWithQuantitySelected } from 'store/event/types';
import { clearEventPageSelections, setEventPageSelections } from 'services/storage';
import {
  getEventSelectionsObj,
  getTicketPriceTotal,
  getTicketSelectionsFromEventData,
} from 'helpers/event';

interface Props {
  candidates: CandidateType[];
  eventData: IEvent;
  handleCandidatesSelected: (index: number, checkValue: boolean) => void;
  onDonateClick: () => void;
  onSelectCandidates: (updatedCandidates: CandidateType[]) => void;
  onUserDonationTotalChange: (value?: number) => void;
  selectedDonationAmount?: number;
  setSelectedDonationAmount: React.Dispatch<React.SetStateAction<number | undefined>>;
  type: IPageType;
}

export const EventSlate: FC<Props> = ({
  type,
  candidates,
  eventData,
  handleCandidatesSelected,
  onDonateClick,
  onSelectCandidates,
  onUserDonationTotalChange,
  selectedDonationAmount,
  setSelectedDonationAmount,
}) => {
  const ref = useRef(null);
  const { urlPartner } = getUrlParams();
  const recipients = useAppSelector(getRecipientsState);
  const [customAmount, setCustomAmount] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [ticketSelections, setTicketSelections] = useState<TicketWithQuantitySelected[]>(
    getTicketSelectionsFromEventData(eventData)
  );

  const onEventPageDonateClick = () => {
    const eventPageSelections = {
      event_tickets: ticketSelections,
      custom_amount: customAmount,
    };

    clearEventPageSelections();
    const eventPageSelectionsString = JSON.stringify(eventPageSelections);
    setEventPageSelections(eventPageSelectionsString);

    onDonateClick();
  };

  useEffect(() => {
    const eventSelections = getEventSelectionsObj();
    if (eventSelections) {
      setCustomAmount(eventSelections.custom_amount || 0);
      setTicketSelections(eventSelections.event_tickets || []);
    }
  }, []);

  useEffect(() => {
    const newAmount = customAmount + getTicketPriceTotal(ticketSelections);
    setSelectedDonationAmount(newAmount);
    onUserDonationTotalChange(newAmount);
  }, [customAmount, ticketSelections]);

  return (
    <Content>
      <Container>
        <PopupModal
          autoClose={false}
          closePopup={() => setIsPopupOpen(false)}
          isSelected={isPopupOpen}
          title="What’s the Impact Score?"
          description="Oath’s Impact Score is calculated from dozens of data points across three metrics: Competitiveness, Stakes, and Financial Need."
        />
        {eventData && (
          <>
            <H4 $color={theme.colors.inkBlue}>Select your Ticket Option</H4>
            <BodySmall $color={theme.colors.gray}>
              Once you select your ticket options, we will direct you to the donation checkout.
            </BodySmall>
            <Tickets
              ticketSelections={ticketSelections}
              setTicketSelections={setTicketSelections}
              customAmount={customAmount}
              setCustomAmount={setCustomAmount}
            />
            <ButtonBrand
              $backgroundColor={theme.colors.oathBlue}
              disabled={!selectedDonationAmount || !candidates?.find(c => c.isSelected)}
              onClick={onEventPageDonateClick}
            >
              Proceed to checkout
            </ButtonBrand>
            <HR />
          </>
        )}
        {urlPartner && type === 'COMPACT' ? (
          <CandidatesContainer>
            <Candidates
              candidates={candidates}
              recipientCount={recipients?.data?.recipients.length}
              setCandidates={onSelectCandidates}
            />
          </CandidatesContainer>
        ) : (
          candidates?.length > 0 && (
            <>
              {recipients.isLoading ? (
                <div className="loader-center">
                  <Loader width={60} height={60} />
                </div>
              ) : (
                <>
                  {eventData?.impact_score_visibility && (
                    <Row $justifyContent="space-between">
                      <Title>Recipients</Title>
                      <Row>
                        <Title>Impact Score</Title>
                        <Spacing $isVertical $size={8} />
                        <Icon
                          name={IconNames.HelpCircle}
                          size={20}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setIsPopupOpen(true)}
                        />
                      </Row>
                    </Row>
                  )}
                  <Spacing $size={8} />
                  {candidates?.map((item, i) => (
                    <CandidateCard
                      key={i}
                      index={i}
                      item={item}
                      selectItem={handleCandidatesSelected}
                      impactScoreVisibility={
                        (urlPartner && eventData?.impact_score_visibility) || false
                      }
                    />
                  ))}
                </>
              )}
            </>
          )
        )}
        <div ref={ref} />
        <Spacing $size={16} />
        <ContributionRules />
      </Container>
    </Content>
  );
};
