import { Dispatch, FC, SetStateAction } from 'react';
import theme from 'config/theme';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { Body, BodySmall, UtilityText } from 'components/Typography';
import { formatNumberAsCurrency } from 'helpers/utils';
import { TicketWithQuantitySelected } from 'store/event/types';
import { TicketContainer, QuantityButton, QuantityContainer, TicketAmountRow } from './styled';

interface Props extends TicketWithQuantitySelected {
  setTicketSelections: Dispatch<SetStateAction<TicketWithQuantitySelected[]>>;
}

export const Ticket: FC<Props> = ({
  id,
  name,
  description,
  price,
  quantity_remaining,
  quantity_selected,
  setTicketSelections,
}) => {
  const handleQuantityChange = (value: number) => {
    const newQuantity = Math.min(quantity_remaining, Math.max(0, quantity_selected + value));

    if (quantity_selected === newQuantity) return;

    setTicketSelections((ticketSelections: TicketWithQuantitySelected[]) =>
      ticketSelections.map(q => (q.id === id ? { ...q, quantity_selected: newQuantity } : q))
    );
  };

  const increaseQuantitySelected = () => {
    handleQuantityChange(1);
  };

  const decreaseQuantitySelected = () => {
    handleQuantityChange(-1);
  };

  return (
    <TicketContainer $disabled={!quantity_remaining} $justifyContent="space-between" key={id}>
      <Column>
        <Body $color="inherit">{name}</Body>
        <UtilityText $color={theme.colors.gray}>{description}</UtilityText>
        <Spacing $size={8} />
        <Body $color="inherit">{formatNumberAsCurrency(price)}</Body>
      </Column>
      <TicketAmountRow>
        {!!quantity_remaining && (
          <QuantityContainer>
            <QuantityButton
              onClick={decreaseQuantitySelected}
              aria-label="Decrease quantity"
              $disabled={quantity_selected === 0}
            >
              -
            </QuantityButton>
            <Body style={{ margin: '0 12px' }}>{quantity_selected || 0}</Body>
            <QuantityButton
              onClick={increaseQuantitySelected}
              aria-label="Increase quantity"
              $disabled={quantity_selected >= quantity_remaining}
            >
              +
            </QuantityButton>
          </QuantityContainer>
        )}
        {!quantity_remaining && <BodySmall $color={theme.colors.gray}>SOLD OUT</BodySmall>}
        {!!quantity_remaining && quantity_remaining <= quantity_selected && (
          <BodySmall $color={theme.colors.errorRed}>Max number of tickets selected</BodySmall>
        )}
        {quantity_remaining <= 5 && quantity_remaining > quantity_selected && (
          <BodySmall $color={theme.colors.gray}>Limited tickets remaining</BodySmall>
        )}
      </TicketAmountRow>
    </TicketContainer>
  );
};
